var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('main',{staticClass:"page-content",attrs:{"role":"main"}},[_c('div',{staticClass:"container-fluid"},[_c('form',{staticClass:"form-card"},[_c('div',{staticClass:"row justify-center"},[_c('div',{staticClass:"col-md-12 col-lg-12 col-xl-6"},[_c('panel',{attrs:{"id":"panel-1"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',{staticClass:"my-2"},[_c('span',{staticClass:"icon-stack fs-xxl mr-2 d-flex justify-content-center align-items-center"},[_c('i',{staticClass:"base base-7 icon-stack-4x opacity-100 color-primary-700"}),_c('i',{staticClass:"fas fa-cog icon-stack-2x opacity-100 color-white"})]),_vm._v(" "+_vm._s(_vm.$t('companies.settings.name'))+" ")])]},proxy:true}],null,false,3759548854)},[_c('div',{staticClass:"panel-container collapse show"},[_c('div',{staticClass:"panel-content mt-4 pb-2"},[_c('h2',{staticClass:"text-lg leading-6 font-medium text-blue-500"},[_vm._v(" "+_vm._s(_vm.$t( 'companies.settings.contact.title' ))+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-lg-12 col-xl-12 my-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"companyName"}},[_vm._v(_vm._s(_vm.$t( 'companies.settings.contact.mail_admin' ))+" *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                    _vm.company.mail_admin
                                                ),expression:"\n                                                    company.mail_admin\n                                                "}],staticClass:"form-control",attrs:{"id":"companyName","required":"","type":"text"},domProps:{"value":(
                                                    _vm.company.mail_admin
                                                )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "mail_admin", $event.target.value)}}}),(
                                                    _vm.isSettingsSend &&
                                                    _vm.nameMessage
                                                )?_c('p',{staticClass:"has-text-danger font-bold text-center is-size-7"},[_vm._v(" "+_vm._s(_vm.nameMessage)+" ")]):_vm._e()])])]),_c('h2',{staticClass:"text-lg leading-6 font-medium text-blue-500"},[_vm._v(" "+_vm._s(_vm.$t( 'companies.settings.sending_mails.title' ))+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-lg-12 col-xl-12 mt-5"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"companyName"}},[_vm._v(_vm._s(_vm.$t( 'companies.settings.sending_mails.sender' ))+" *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                    _vm.company.settings_sender_name
                                                ),expression:"\n                                                    company.settings_sender_name\n                                                "}],staticClass:"form-control",attrs:{"id":"companyName","required":"","type":"text"},domProps:{"value":(
                                                    _vm.company.settings_sender_name
                                                )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "settings_sender_name", $event.target.value)}}}),(
                                                    _vm.isSettingsSend &&
                                                    _vm.nameMessage
                                                )?_c('p',{staticClass:"has-text-danger font-bold text-center is-size-7"},[_vm._v(" "+_vm._s(_vm.nameMessage)+" ")]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-lg-12 col-xl-12 mt-5"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"companyEmail"}},[_vm._v(_vm._s(_vm.$t( 'companies.settings.sending_mails.sender_mail' ))+" *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                    _vm.company.settings_sender_mail
                                                ),expression:"\n                                                    company.settings_sender_mail\n                                                "}],staticClass:"form-control",attrs:{"id":"companyEmail","required":"","type":"email"},domProps:{"value":(
                                                    _vm.company.settings_sender_mail
                                                )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "settings_sender_mail", $event.target.value)}}})]),(
                                                _vm.isSettingsSend &&
                                                _vm.mailMessage
                                            )?_c('p',{staticClass:"has-text-danger font-bold text-center is-size-7"},[_vm._v(" "+_vm._s(_vm.mailMessage)+" ")]):_vm._e()])]),_c('h2',{staticClass:"text-lg leading-6 font-medium text-blue-500 pt-4"},[_vm._v(" "+_vm._s(_vm.$t( 'companies.settings.sending_mails.advanced_config' ))+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-lg-12 col-xl-6 mt-5"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"mailProtocol"}},[_vm._v(" "+_vm._s(_vm.$t( 'companies.settings.sending_mails.protocol' )))]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(
                                                    _vm.company.settings_mail_protocol
                                                ),expression:"\n                                                    company.settings_mail_protocol\n                                                "}],staticClass:"form-control select",attrs:{"id":"mailProtocol","name":"mailProtocol"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.company, "settings_mail_protocol", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"smtp"}},[_vm._v(" SMTP ")]),_c('option',{attrs:{"value":"pop"}},[_vm._v(" POP ")]),_c('option',{attrs:{"value":"imap"}},[_vm._v(" IMAP ")])])])]),_c('div',{staticClass:"col-md-12 col-lg-12 col-xl-6 mt-5"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"mailHost"}},[_vm._v(" "+_vm._s(_vm.$t( 'companies.settings.sending_mails.host' )))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                    _vm.company.settings_mail_host
                                                ),expression:"\n                                                    company.settings_mail_host\n                                                "}],staticClass:"form-control",attrs:{"id":"mailHost","type":"text"},domProps:{"value":(
                                                    _vm.company.settings_mail_host
                                                )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "settings_mail_host", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-lg-12 col-xl-6 mt-5"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"mailPort"}},[_vm._v(" "+_vm._s(_vm.$t( 'companies.settings.sending_mails.port' )))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                    _vm.company.settings_mail_port
                                                ),expression:"\n                                                    company.settings_mail_port\n                                                "}],staticClass:"form-control",attrs:{"id":"mailPort","type":"number"},domProps:{"value":(
                                                    _vm.company.settings_mail_port
                                                )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "settings_mail_port", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-12 col-lg-12 col-xl-6 mt-5"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"mailUsername"}},[_vm._v(" "+_vm._s(_vm.$t( 'companies.settings.sending_mails.username' )))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                    _vm.company.settings_mail_username
                                                ),expression:"\n                                                    company.settings_mail_username\n                                                "}],staticClass:"form-control",attrs:{"id":"mailUsername","type":"text"},domProps:{"value":(
                                                    _vm.company.settings_mail_username
                                                )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "settings_mail_username", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-lg-12 col-xl-6 mt-5"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"mailPassword"}},[_vm._v(" "+_vm._s(_vm.$t( 'companies.settings.sending_mails.password' )))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                    _vm.company.settings_mail_password
                                                ),expression:"\n                                                    company.settings_mail_password\n                                                "}],staticClass:"form-control",attrs:{"id":"mailPassword","type":"password"},domProps:{"value":(
                                                    _vm.company.settings_mail_password
                                                )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "settings_mail_password", $event.target.value)}}})]),(
                                                _vm.isSettingsSend &&
                                                _vm.passwordmessage
                                            )?_c('p',{staticClass:"has-text-danger font-bold text-center is-size-7 mx-4"},[_vm._v(" "+_vm._s(_vm.passwordmessage)+" ")]):_vm._e()]),_c('div',{staticClass:"col-md-12 col-lg-12 col-xl-6 mt-5"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form-label",attrs:{"for":"mailPasswordConfirmation"}},[_vm._v(" "+_vm._s(_vm.$t( 'companies.settings.sending_mails.password_confirmation' )))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                    _vm.company.settings_mail_password_confirmation
                                                ),expression:"\n                                                    company.settings_mail_password_confirmation\n                                                "}],staticClass:"form-control",attrs:{"id":"mailPasswordConfirmation","type":"password"},domProps:{"value":(
                                                    _vm.company.settings_mail_password_confirmation
                                                )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "settings_mail_password_confirmation", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-lg-12 col-xl-12 mt-5"},[_c('div',{staticClass:"form-group"},[_c('h3',{staticClass:"text-lg leading-6 font-medium text-blue-500"},[_vm._v(" Logo ")]),_c('FormFileUpload',{staticClass:"formFileUpload",attrs:{"attribute":_vm.logoAttribute,"isOnePicture":true},on:{"add_company_files":_vm.addCompanyLogo}})],1)])])])])]),_c('div',{staticClass:"d-flex justify-center"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){$event.preventDefault();return _vm.sendCompanySettingsHandler()}}},[_vm._v(" "+_vm._s(_vm.$t('companies.settings.change_settings'))+" ")])]),(_vm.isSettingsModified)?_c('div',{staticClass:"d-flex justify-center"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('companies.settings.settings_modified'))+" ")])]):_vm._e()],1),_c('div',{staticClass:"col-md-12 col-lg-12 col-xl-6"},[_c('panel',{attrs:{"id":"panel-1"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',{staticClass:"my-2"},[_c('span',{staticClass:"icon-stack fs-xxl mr-2 d-flex justify-content-center align-items-center"},[_c('i',{staticClass:"base base-7 icon-stack-4x opacity-100 color-primary-700"}),_c('i',{staticClass:"fas fa-cog icon-stack-2x opacity-100 color-white"})]),_vm._v(" Entête de mails ")])]},proxy:true}],null,false,4170474678)},[_c('div',{staticClass:"panel-container collapse show"},[_c('div',{staticClass:"panel-content mt-4 pb-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-lg-12 col-xl-12 mt-5"},[_c('div',{staticClass:"form-group"},[_c('p',{staticClass:"text-sm"},[_vm._v(" Mise en page html ")]),_c('b-field',{staticClass:"w-full h-full"},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(
                                                        _vm.headerMail.settings_mail_template
                                                    ),callback:function ($$v) {_vm.$set(_vm.headerMail, "settings_mail_template", $$v)},expression:"\n                                                        headerMail.settings_mail_template\n                                                    "}})],1)],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-lg-12 col-xl-12 mt-5"},[_c('div',{staticClass:"form-group"},[_c('p',{staticClass:"text-sm"},[_vm._v(" Autres images ")]),_c('FormFileUpload',{staticClass:"formFileUpload",attrs:{"attribute":_vm.picturesAttribute,"isOnePicture":false},on:{"add_company_files":_vm.addCompanyPictures}})],1)])])])])]),_c('div',{staticClass:"d-flex justify-center"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){$event.preventDefault();return _vm.sendHeaderMailsHandler()}}},[_vm._v(" "+_vm._s(_vm.$t('companies.settings.change_settings'))+" ")])]),(_vm.isHeaderMailModified)?_c('div',{staticClass:"d-flex justify-center"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('companies.settings.settings_modified'))+" ")])]):_vm._e()],1)])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }